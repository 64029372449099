@import "../public/lib/bootstrap/less/variables.less";

@font-family-sans-serif: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

@headings-font-family:    "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
@headings-font-weight:    700;

@navbar-inverse-color:             #fff;
@navbar-inverse-bg:                #f91717;
@navbar-inverse-border:            darken(@navbar-inverse-bg, 6.5%);

// Navbar links
@navbar-inverse-link-color:                #fff;
@navbar-inverse-link-hover-color:          #f8f8f8;
@navbar-inverse-link-hover-bg:             transparent;
@navbar-inverse-link-active-color:         #fff;
@navbar-inverse-link-active-bg:            #000;

// Navbar brand label
@navbar-inverse-brand-color:               @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color:         darken(@navbar-inverse-brand-color, 10%);
@navbar-inverse-brand-hover-bg:            transparent;

// Navbar toggle
@navbar-inverse-toggle-hover-bg:           #000;
@navbar-inverse-toggle-icon-bar-bg:        #fff;
@navbar-inverse-toggle-border-color:       #fff;

@icon-font-path:          "../lib/bootstrap/fonts/";
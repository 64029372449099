.glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg);}
    to { transform: scale(1) rotate(360deg);}
}

.tracking,
.toolbar {
    margin-bottom: 20px;
}

.btn-hover .btn {
    visibility: hidden;
    margin-right: 10px;
}

.btn-hover:hover .btn {
    visibility: visible;
}

.navbar-3b {
    font-family: @headings-font-family;
    font-weight: 700;
    text-transform: uppercase;
}

.navbar-brand img {
    width: 34px;
    display: inline-block;
    margin-top: -8px;
    margin-right: 5px;
}
  
.portal-dashboard {
  .jumbotron {
    @media (min-width: @screen-md-min) {
      min-height: 400px;
    }
    
    @media (min-width: @screen-lg-min) {
      min-height: 340px;
    }
  }
  
  .dash-link-desc {
    @media (min-width: @screen-md-min) {
      min-height: 180px;
    }
    
    @media (min-width: @screen-lg-min) {
      min-height: 120px;
    }
  }
}
  
.label-1 {
  background-color: #f44236;
}
  
.label-2 {
  background-color: #ff5622;
}
  
.label-3 {
  background-color: #ffc107;
}
  
.label-4 {
  background-color: #03a8f4;
}
  
.label-5 {
  background-color: #607d8b;
}
  
.time-table {
  .table-bordered > thead > tr > th, 
  .table-bordered > tbody > tr > td {
    padding: 4px;
    font-size: 12px;
  }
}
  /* Portal Roadmap */
  
  .display-trello-cards {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    
    .trello-card {
      display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      position: relative;
       -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
      -ms-flex-direction: column;
        flex-direction: column;
      margin-bottom: 20px;
      
      padding-right: 10px !important;
      padding-left: 10px !important;
      
      .trello-card-copy {
        background: #337ab7;
        padding: 10px;
        border-radius: 6px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        max-height: 100%;
        position: relative;
        white-space: normal;
        color: #000;
      }
      .trello-card-copy:hover {
        background: #286090;
        cursor: pointer;
        text-decoration: none;
      }
      
      .trello-card-data {
        font-size: 14px;
        background: #eeeeee;
        padding: 10px;
        margin-bottom: 0px;
        word-wrap: break-word;
        border-radius: 4px;
      }
      .trello-card-url {
        font-size: 16px;
        display: block;
        color: #fff;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
  .portal_select_clients {
  position: relative;
    overflow: auto;
    padding-bottom: 20px;
  }